import { tryCatch } from "@thalesrc/js-utils";
import { pathWithQueryString } from "../helpers/pathWithQueryString";
import { GET_CUSTOMER_URL, LOGIN_URL, LOGIN_URL_CODE, LOGIN_URL_PHONE, LOGOUT_URL } from "../utils/url";
import http from "./http";

// export async function loginUser(payload) {
//   const url = LOGIN_URL;

//   const [error, result] = await tryCatch(http.post(url, payload));

//   if (error) throw error;

//   return result.data.data;
// }

export async function loginUser(payload) {
  const url = LOGIN_URL;
  let urlencoded = new URLSearchParams();
  urlencoded.append("client_id", "WebMvcClient");
  urlencoded.append("client_secret", "secret");
  urlencoded.append("grant_type", "client_credentials");

  const [error, result] = await tryCatch(
    http.post(url, urlencoded, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        tenantId: JSON.parse(localStorage?.getItem("selectedFactory")) ? JSON.parse(localStorage?.getItem("selectedFactory"))?.label : 0,
      },
    })
  );

  if (error) throw error;

  return result.data;
}

export async function loginUserCode(payload) {
  const url = pathWithQueryString(LOGIN_URL_CODE, payload);
  const [error, result] = await tryCatch(http.get(url));
  if (error) throw error;
  return result.data;
}

export async function phoneVerify(payload) {
  const url = LOGIN_URL_PHONE;

  const [error, result] = await tryCatch(
    http.get(url + `?phoneNumber=${payload.phoneNumber}`, {
      headers: {
        platformCode: payload.platformCode,
        tenantId: JSON.parse(localStorage?.getItem("selectedFactory")) ? JSON.parse(localStorage?.getItem("selectedFactory"))?.label : 0,
      },
    })
  );

  if (error) throw error;

  return result.data;
}

export function getCustomer() {
  return new Promise((resolve, reject) => {
    const url = `${GET_CUSTOMER_URL}?isWebUser=${true}`;
    http
      .get(url)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}
export function getLogout() {
  return new Promise((resolve, reject) => {
    http
      .get(LOGOUT_URL)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}
